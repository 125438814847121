/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  }

  body {
    @apply bg-gray-50;
  }
}

@layer components {
  .container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }
  
  .btn {
    @apply inline-flex items-center justify-center px-6 py-3 rounded-lg font-medium transition-colors;
  }
  
  .btn-primary {
    @apply bg-brand-blue-500 hover:bg-brand-blue-600 text-white;
  }
  
  .btn-secondary {
    @apply bg-brand-green-500 hover:bg-brand-green-600 text-white;
  }
  
  .btn-outline {
    @apply border-2 border-brand-blue-500 text-brand-blue-500 hover:bg-brand-blue-500 hover:text-white;
  }
  /* Add to src/index.css */
@layer components {
  .search-container {
    @apply relative z-10 -mt-8 mx-auto max-w-4xl;
  }
  
  .feature-icon-container {
    @apply w-16 h-16 flex items-center justify-center rounded-full 
      bg-brand-blue-50 text-brand-blue-500;
  }
}
}